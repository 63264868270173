<template>
  <div class="content-container">
    <PageTitle :title="$t('MISSIONS.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t(missionId ? 'MISSIONS.EDIT' : 'CREATE_MISSION.NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_CMS.MISSIONS"/>

    <!-- Form -->
    <FormWrapper v-if="!missionId || initialData.name !== null" :send-form-data="validateData" class="page-form">
      <template #form-content>

        <!-- Name -->
        <InputField :field-title="$t('CREATE_MISSION.NAME')"
                    :field-name="'name'"
                    :rules="'required'"
                    :type="'text'"
                    :value="initialData.name"
                    :bind-value="true"
                    :placeholder="$t('CREATE_MISSION.NAME_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="form-element"/>


        <!-- Description -->
        <LargeInputField :field-title="$t('CREATE_MISSION.DESCRIPTION')"
                         :field-name="'description'"
                         :rules="'required'"
                         :type="'text'"
                         :value="initialData.description"
                         :bind-value="true"
                         :placeholder="$t('CREATE_MISSION.DESCRIPTION_PLACEHOLDER')"
                         :cy-selector="'name-input'"
                         :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                         :api-error-text="''"
                         class="form-element"/>

        <!-- Image upload -->
        <ImageInput :title="$t('DIAS.IMAGE')"
                    :media-files="initialData ? initialData.mediaFiles : null"
                    :start-file="initialData ? initialData.mediaFileUrl : null"
                    :error-text="imageError"
                    @file="updateImage" />

        <!-- Tags -->
        <TagSelector ref="tagSelector"
                     :field-title="$t('EXTRA_GAMES.TAGS')"
                     :field-name="'tags'"
                     :rules="'required'"
                     :pre-selected="selectedTags"
                     :options="tagData"
                     :update-parent="updateTags"
                     :error-text="tagError"/>
        <div class="time-input-wrapper">

          <h1 class="form-input-title"> {{ $t('CREATE_MISSION.START_TIME') }} </h1>
          <div class="time-input-fields">
            <label for="hours" class="time-input-field">
              <input
                id="hours"
                v-model="hoursInput"
                type="number"
                placeHolder="00"
                class="form-input-time"
                @change="onTimeInput"
              />
            </label>

            <p> {{ $t('HOUR') }} </p>

            <label for="minutes" class="time-input-field">
              <input
                id="minutes"
                v-model="minutesInput"
                type="number"
                placeHolder="00"
                class="form-input-time"
                @change="onTimeInput"
              />
            </label>

            <p> {{ $t('MINUTES') }} </p>
          </div>
          <p v-if="hoursError" class="text-error">{{ hoursError }}</p>
        </div>

        <!--        <DateSelector-->
        <!--          :on-change="updateStartDate"-->
        <!--          :start-date="activatedFrom"-->
        <!--          :clearable="false"-->
        <!--          :title="$t('CREATE_MISSION.LIVE_FROM')"-->
        <!--          :inline-subtitle="`(${$t('OPTIONAL')})`"/>-->

        <!--        <DateSelector-->
        <!--          :on-change="updateEndDate"-->
        <!--          :start-date="activatedUntil"-->
        <!--          :clearable="false"-->
        <!--          :title="$t('CREATE_MISSION.LIVE_TILL')"-->
        <!--          :inline-subtitle="`(${$t('OPTIONAL')})`"/>-->

        <!-- Status -->
        <Dropdown id="status"
                  :field-title="$t('CREATE_MISSION.STATUS')"
                  :dropdown-options="statusTypes"
                  :placeholder="$t('CREATE_MISSION.STATUS')"
                  :field-name="'status'"
                  :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                  :callback="() => {}"
                  :selected-option="initialData.isActivated !== undefined ? statusTypes[initialData.isActivated] : statusTypes[0]"
                  :api-error-text="''"
                  class="form-element"/>

        <!-- Status -->
        <Dropdown id="missionCategoryId"
                  :field-title="$t('CREATE_MISSION.CATEGORY')"
                  :dropdown-options="missionCategories"
                  :placeholder="''"
                  :field-name="'missionCategoryName'"
                  :selected-option="missionId ? missionCategories[Number.parseInt(initialData.missionCategoryId) - 1] : selectedMissionCategory"
                  :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                  :callback="(value) => selectMissionCategory(value)"
                  :api-error-text="''"
                  class="form-element"/>


        <Dropdown v-if="selectedMissionCategory && selectedMissionCategory.id === 3"
          :dropdown-options="dayOfMMOptions"
          :selected-option="missionId ? dayOfMMOptions[Number.parseInt(initialData.dayOfMM) - 1] : null"
          :placeholder="$t('CREATE_MISSION.SELECTED_DAY_PLACEHOLDER')"
          :field-title="$t('DAY_WITHIN_WEEK_OF_MM')"
          :field-name="'dayOfMM'"
          :callback="() => {}"
          :api-error-text="dayOfMMAPIError"
          class="day-dropdown"
        />
        <!-- Level select, only show for the demo missions and the week of the media wisdom -->
        <div v-show="gameWithDifficulty" class="difficulty-wrapper">
          <h1 class="form-input-title"> {{ $t('CREATE_MISSION.MISSION_LEVEL') }} </h1>
          <Checkbox :field-title="''"
            :text="$t('CREATE_MISSION.MISSION_LEVEL_EASY')"
            :field-name="'missionDifficultyEasy'"
            :value="!!initialData.difficulties?.includes(1)"
            :rules="''"
            :is-basic-checkbox="true"
            :is-bold="true"
          />
          <Checkbox :field-title="''"
            :text="$t('CREATE_MISSION.MISSION_LEVEL_HARD')"
            :field-name="'missionDifficultyHard'"
            :value="!!initialData.difficulties?.includes(2)"
            :rules="''"
            :is-basic-checkbox="true"
            :is-bold="true"
          />
          <p v-if="difficultyError" class="text-error">{{ difficultyError }}</p>
        </div>
        <div v-if="selectedMissionCategory && selectedMissionCategory.id === 3">
          <p class="form-input-title">{{ $t('CREATE_MISSION.JOKERS') }}</p>
          <div v-for="(joker,index) in jokerTypes" :key="index" class="joker-dropdown" @click="selectJoker(joker)">
            <BasicCheckbox :enabled="selectedJoker && joker.id === selectedJoker.id"/>
            {{ joker.name }}
          </div>
          <div class="joker-dropdown" @click="selectJoker(null)">
            <BasicCheckbox :enabled="!selectedJoker"/>
            {{ $t('NONE') }}
          </div>
          <ImageInput :title="$t('CREATE_MISSION.WEEK_IMAGE_ONE')"
                      :media-files="initialData ? initialData.mediaFiles : null"

                      :start-file="initialData ? initialData.activeCoverMediaFileUrl : null"
                      @file="updateActiveImage" />

          <ImageInput :title="$t('CREATE_MISSION.WEEK_IMAGE_TWO')"
                      :media-files="initialData ? initialData.mediaFiles : null"
                      :start-file="initialData ? initialData.playedCoverMediaFileUrl : null"
                      @file="updatePlayedImage" />
        </div>


        <!-- <Checkbox :field-title="''"
                  :text="$t('CREATE_MISSION.SUITABLE')"
                  :field-name="'suitableForHighSchool'"
                  :value="!!initialData.suitableForHighSchool"
                  :rules="''"/> -->

        <div class="border-line">
        </div>

        <!-- <h1 class="page-section-title section-title">{{ $t('CREATE_MISSION.INSTRUCTIONS') + " (" + $t('OPTIONAL') + ")" }}</h1> -->

        <!-- Instructions - title -->
        <!-- <InputField :field-title="$t('CREATE_MISSION.TITLE')"
                    :field-name="'instructionsTitle'"
                    :rules="'optional'"
                    :type="'text'"
                    :placeholder="$t('CREATE_MISSION.TITLE_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="form-element"/>
        <div v-for="(instruction,index) in instructions" :key="index">
          <RichTextField :field-title="$t('CREATE_MISSION.INSTRUCTIONS')"
                         :input-callback="instructionInput"
                         :value="instruction"
          />
        </div>
        <InlineButton v-if="instructions.length < 4"
                      :text="$t('DIAS.SELECT_ANSWER.ADD_MORE_ANSWERS')"
                      :icon="require('@/assets/icons/icn_add.svg')"
                      @click="addInstruction()"
        /> -->

      </template>

      <template #button-submit>
        <button type="submit"
                class="pink-button submit-button">
          {{ $t('CREATE_MISSION.SAVE_AND_CONTINUE') }}

        </button>
      </template>

    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { CREATE_MISSION, EDIT_MISSION, GET_TAGS } from '@/store/modules/cms/actions'
import { GET_MISSION_CATEGORIES, GET_MISSION_DETAILS, GET_JOKER_TYPES } from '@/store/modules/cms/missions/actions.js'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { ref, watch } from 'vue'
import TagSelector from '@/components/yo-form/TagSelector'
import Checkbox from '@/components/yo-form/Checkbox'
import BasicCheckbox from '@/components/elements/basic/Checkbox'
import InputField from '@/components/yo-form/InputField'
import LargeInputField from '@/components/yo-form/LargeInputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import Dropdown from '@/components/yo-form/Dropdown'
import { useRoute, useRouter } from 'vue-router'
// import DateSelector from '@/components/elements/inputFields/DateSelector'
// import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
// import InlineButton from '@/components/elements/basic/InlineButton'
// import { convertyyyymmdd } from '@/utils/helpers/DateFormatter.js'
import ImageInput from '@/components/elements/inputFields/ImageInput.vue'
import { useI18n } from 'vue-i18n'
import { jokerTypesArr } from '@/utils/data/jokerTypes'

export default {
  name: 'CreateMission',
  components: {
    BackButton,
    PageTitle,
    InputField,
    FormWrapper,
    Dropdown,
    TagSelector,
    Checkbox,
    // DateSelector,
    LargeInputField,
    // RichTextField,
    // InlineButton,
    BasicCheckbox,
    ImageInput,
  },
  props: {
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    //only set if editing
    const missionId = route.params.id

    if (missionId) {
      store.dispatch(GET_MISSION_DETAILS, missionId)
    }

    const { t } = useI18n({})

    const initialData = computed(() => missionId ? store.getters.getMissionDetails : {})
    const gameWithDifficulty = ref(true);

    const dayOfMMAPIError = ref('')

    //setInterval(() => console.log(initialData.value), 1000)
    getTags()


    watch(() => initialData.value, (curr, prev) => {
      selectedMissionCategory.value = missionCategories.value.find(x => x.id === curr.missionCategoryId)
      // activatedFrom.value = convertyyyymmdd(curr.activatedFrom)
      // activatedUntil.value = convertyyyymmdd(curr.activatedUntil)
      selectedJoker.value = curr.joker
      selectedTags.value = curr.tags.map(x => x.id)
      minutesInput.value = curr.durationEstimated
      gameWithDifficulty.value = curr.missionCategoryId === 1 || curr.missionCategoryId === 3;
      onTimeInput()
    })


    function getTags() {
      return store.dispatch(GET_TAGS, {})
    }

    const tagData = computed(() => store.getters.getTags)

    const selectedTags = ref([])

    function updateTags(selected) {
      selectedTags.value = selected
    }


    const statusTypes = [
      {
        id: 0,
        name: t('CONCEPT')
      },
      {
        id: 1,
        name: t('PUBLISHED')
      }
    ]

    const dayOfMMOptions = [
      { id: 0, name: '1' },
      { id: 1, name: '2' },
      { id: 2, name: '3' },
      { id: 3, name: '4' },
      { id: 4, name: '5' }
    ]

    store.dispatch(GET_MISSION_CATEGORIES)


    const missionCategories = computed(() => store.getters.getMissionCategories)
    const selectedMissionCategory = ref(missionCategories.value ? missionCategories[0] : null)    

    if (initialData.value) {
      selectMissionCategory(initialData.value.missionCategoryId)
    }

    watch(() => missionCategories.value, (curr, prev) => {
      selectedMissionCategory.value = missionCategories.value.find(x => x.id === curr.missionCategoryId)
    })

    function selectMissionCategory(choice) {
      selectedMissionCategory.value = choice
    }

    watch(() => missionCategories.value, (curr, prev) => {
      selectedMissionCategory.value = curr[0]
    })

    watch(() => selectedMissionCategory.value, (curr) => {
      gameWithDifficulty.value = curr.id === 1 || curr.id === 3;
    })


    /** Form **/

    const minutesInput = ref('')
    const hoursInput = ref('')

    const maxMinutes = 59
    const maxHours = 23

    //helper for inputting time
    function onTimeInput() {
      if (minutesInput.value > maxMinutes) {
        const excessHours = Math.floor(minutesInput.value / 60)
        minutesInput.value = minutesInput.value % 60
        hoursInput.value = parseInt(excessHours)
      }
      if (minutesInput.value.length > 2) {
        minutesInput.value = minutesInput.value.slice(0, 2)
      }
      if (hoursInput.value > maxHours) {
        hoursInput.value = maxHours
        minutesInput.value = maxMinutes
      }
      if (hoursInput.value.length > 2) {
        hoursInput.value = hoursInput.value.slice(0, 2)
      }
    }

    const demoMissionId = 3 //used for checking specific parts to show if demo mission selected

    function customErrors(data) {
      let valid = true
      if (!selectedTags.value || selectedTags.value.length === 0) {
        tagError.value = t('CREATE_MISSION.TAGS_ERROR')
        valid = false
      } else {
        tagError.value = ''
      }
      if (!image.value) {
        imageError.value = t('CREATE_MISSION.IMAGE_ERROR')
        valid = false
      } else {
        imageError.value = ''
      }
      if (!hoursInput.value) {
        hoursError.value = t('CREATE_MISSION.HOURS_ERROR')
      } else {
        hoursError.value = ''
      }
      // No level selected
      if(gameWithDifficulty.value) {
        if (!data.missionDifficultyEasy && !data.missionDifficultyHard) {
          difficultyError.value = t('CREATE_MISSION.LEVEL_ERROR')
          valid = false
        }
      }
      difficultyError.value = ''
      return valid
    }


    function validateData(data) {
      if (!customErrors(data)) {
        return
      }
      if (selectedJoker.value) {
        data.missionJokerId = selectedJoker.value.id
      }
      // Level select
      data.gameDifficulties = [];
      if(!gameWithDifficulty.value) {
        data.gameDifficulties = [1, 2];
      } else {
        if (data.missionDifficultyEasy) {
          data.gameDifficulties.push(1)
        }
        if (data.missionDifficultyHard) {
          data.gameDifficulties.push(2)
        }
      }

      data.tags = selectedTags.value
      data.durationEstimated =
        parseInt(minutesInput.value ? minutesInput.value : 0) +
        parseInt(hoursInput.value ? hoursInput.value : 0) * 60
      data.isActivated = data.status === statusTypes[1].name
      data.missionCategoryId = selectedMissionCategory.value.id
      // data.activatedFrom = activatedFrom.value.toISOString()
      // data.activatedUntil = activatedUntil.value.toISOString()
      onSubmit(data)
    }

    function onSubmit(data) {
      if (missionId) { //we are editing
        data.id = missionId
        data.mediaFile = image.value
        data.activeCoverMediaFile = activeImage.value
        data.playedCoverMediaFile = playedImage.value
        // if (data.suitableForHighSchool === undefined) {
        //   data.suitableForHighSchool = initialData.value.suitableForHighSchool
        // }
        store.dispatch(EDIT_MISSION, data).then(result => {
          if (result && result.status === 204) {
            router.push({ name: ROUTE_NAMES_CMS.MISSIONS })
          } else {
            console.log('response', result)
          }
        })
      } else {
        data.canBePlayedAlone = false
        data.mediaFile = image.value
        data.activeCoverMediaFile = activeImage.value
        data.playedCoverMediaFile = playedImage.value
        // if (data.suitableForHighSchool === undefined) {
        //   data.suitableForHighSchool = false
        // }
        store.dispatch(CREATE_MISSION, data).then(result => {
          if (result && result.status === 201) {
            router.push({ name: ROUTE_NAMES_CMS.MISSIONS })
          } else {
            if (result.data.error === 'day_already_has_mission') {
              dayOfMMAPIError.value = 'Deze dag heeft al een missie.'
            }
          }
        })
      }
    }

    const jokerTypes = jokerTypesArr;

    const selectedJoker = ref(null)
    function selectJoker(choice) {
      selectedJoker.value = choice
    }


    // function updateStartDate(date) {
    //   console.log('got back', date)
    //   const dateObject = new Date(Date.parse(date))
    //   activatedFrom.value = dateObject
    // }
    //
    // function updateEndDate(date) {
    //   const dateObject = new Date(Date.parse(date))
    //   activatedUntil.value = dateObject
    // }

    const image = ref(null)
    function updateImage(data) {
      image.value = data
    }

    const activeImage = ref(null)
    function updateActiveImage(data) {
      activeImage.value = data
    }

    const playedImage = ref(null)
    function updatePlayedImage(data) {
      playedImage.value = data
    }

    const tagError = ref('')
    const imageError = ref('')
    const hoursError = ref('')
    const difficultyError = ref('')

    return {
      // only set if initial data
      missionId,
      initialData,

      // updateStartDate,
      // updateEndDate,
      // convertyyyymmdd,

      jokerTypes,
      selectedJoker,
      selectJoker,

      statusTypes,
      missionCategories,
      selectedMissionCategory,
      gameWithDifficulty,
      selectMissionCategory,
      demoMissionId,

      // activatedFrom,
      // activatedUntil,

      /** Form **/
      minutesInput,
      hoursInput,
      onTimeInput,

      validateData,
      onSubmit,

      ROUTE_NAMES_CMS,

      tagData,
      updateTags,
      selectedTags,
      tagError,
      imageError,
      hoursError,
      difficultyError,

      updateImage,
      updateActiveImage,
      updatePlayedImage,
      dayOfMMOptions,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.border-line{
  border-top: 2px solid #DDE3F0;
  margin-bottom: rem(20);
  margin-top: rem(20);
  widows: 100%;
}

.time-input-wrapper{
  margin-top: rem(20);
  margin-bottom: rem(20);
}

.time-input-fields{
    display: flex;
    flex-wrap: nowrap;
    gap: rem(10);
    align-items: center;
    justify-content: flex-start;
    width: rem(100);
}

.day-dropdown {
  margin-bottom: rem(28);
}

.form-input-time {
  border: none;
  border-radius: rem(8);
  background-color: white;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: var(--blue_dark_01);
  font-size: rem(16);
  height: rem(46);
  padding: rem(11) rem(11) rem(11) rem(11);
  width: rem(50);
}

.joker-dropdown{
  display: flex;
  align-items: center;
  gap: rem(20);
  margin: rem(10) rem(0)
}

.difficulty-wrapper {
  margin-bottom: rem(28);
}
</style>
