// export const jokerTypes = {
//   JOKER_REMOVE_HALF: 1,
//   JOKER_DOUBLE_BITS: 2,
//   JOKER_TIP: 3,
//   JOKER_SAVE_STREAK: 4
// }

export const jokerTypesArr = [
  {
    description: 'Laat een foute antwoordoptie wegvallen.',
    icon: require('../../assets/jokers/50_50.png'),
    id: 1,
    name: 'Streep een fout antwoord weg'
  },
  {
    description: 'Verdien het dubbele aantal bits voor een goed antwoord.',
    icon: require('../../assets/jokers/double_bits.png'),
    id: 2,
    name: 'Dubbele bits'
  },
  {
    description: 'Ontvang een extra tip.',
    icon: require('../../assets/jokers/tip.png'),
    id: 3,
    name: 'Tip'
  },
  {
    description: 'Behoud jullie streak, zelfs als jullie de vraag verkeerd beantwoorden.',
    icon: require('../../assets/jokers/streak_keep.png'),
    id: 4,
    name: 'Behoud streak'
  }
]
